import React from "react";
import { Routes } from "react-router-dom";
import OpenRoutes from "./OpenRoutes";
import PrivateRoutes from "./PrivateRoutes";

const RoutesApp = () => {
	return (
		<Routes>
			{PrivateRoutes}
			{OpenRoutes}
		</Routes>
	);
};

export default RoutesApp;
