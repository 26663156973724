import React from "react";
import { Route } from "react-router-dom";
import Aplication from "shade/layouts/App";

const Dashboard = React.lazy(() => import("pages/dashboard"));
const LibraryPage = React.lazy(() => import("pages/library"));
const TemplatesPage = React.lazy(() => import("pages/templates"));
const VideosPage = React.lazy(() => import("pages/videos"));
const FormVideoPage = React.lazy(() => import("pages/form-video"));
const HelpPage = React.lazy(() => import("pages/help"));

const PrivateRoutes = (
	<Route path={`/`} element={<Aplication />}>
		<Route index element={<Dashboard />} />
		<Route path='/library' element={<LibraryPage />} />
		<Route path='/templates' element={<TemplatesPage />} />
		<Route path='/videos' element={<VideosPage />} />
		<Route path='/videos/:uuid' element={<FormVideoPage />} />
		<Route path='/help' element={<HelpPage />} />
	</Route>
);

export default PrivateRoutes;
