import { Skeleton } from "@mui/material";
import { Suspense } from "react";
import { ImageBox } from "./style";

type ImageLoadingProps = {
	src: string;
	alt: string;
	width?: number;
	height?: number;
	heightLoading?: number;
};
const ImagePromisse = ({ src, alt, width, height }: ImageLoadingProps) => {
	const image = new Image();
	image.src = src;

	if (!image.complete) {
		throw new Promise(resolve => {
			image.onload = resolve;
			image.onerror = resolve;
		});
	}

	return <ImageBox src={src} loading='lazy' alt={alt} width={width} height={height} />;
};

export const ImageLoading = (props: ImageLoadingProps) => {
	return (
		<Suspense
			fallback={
				<Skeleton
					variant='rectangular'
					width={props.width}
					height={props.heightLoading}
					style={{ maxWidth: "100%", maxHeight: props?.width ?? "100%" }}
				/>
			}
		>
			<ImagePromisse {...props} />
		</Suspense>
	);
};

export default ImageLoading;
