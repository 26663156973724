import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";

type DialogComponentProps = {
	title: string;
	children?: React.ReactNode;
	actions?: React.ReactNode;
	open: boolean;
	onClose: () => void;
};

const DialogComponent = (props: DialogComponentProps) => {
	return (
		<Dialog fullWidth maxWidth='xl' open={props.open} onClose={() => props.onClose()}>
			<DialogTitle>
				{props.title}
				<IconButton
					aria-label='close'
					onClick={() => props.onClose()}
					className='closeX'
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: theme => theme.palette.grey[500]
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>
			{props.children}
		</Dialog>
	);
};

export default DialogComponent;
