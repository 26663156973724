import { ReducersProps } from "store/reducers/types";
import { useDispatch, useSelector } from "react-redux";
import { TemplateProps } from "store/reducers/template/types";
import TemplateService from "services/api/template";

const useTemplate = () => {
	const dispatch = useDispatch();
	const { templates, total } = useSelector((state: ReducersProps) => state.template);

	async function getTemplates(page: string, quantity: number) {
		const response = await TemplateService.getTemplates(page, quantity);
		if (!response) {
			return;
		}
		updateTemplates(response.templates);
		updateTotal(response.total);
	}

	function updateTemplates(templates: TemplateProps[]) {
		dispatch({ type: "SET_TEMPLATES", templates });
	}

	function updateTotal(total: number) {
		dispatch({ type: "SET_TOTAL", total });
	}

	return {
		templates,
		total,
		getTemplates,
		updateTemplates
	};
};

export default useTemplate;
