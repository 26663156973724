import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthService from "services/api/auth";
import { ReducersProps } from "store/reducers/types";
import { ContractsProps, UserProps } from "store/reducers/user/types";

const useAuth = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user, contracts, contractActive, contractStartChange } = useSelector(
		(state: ReducersProps) => state.user
	);

	async function submitUser(username: string, password: string) {
		const data = await AuthService.submit(username, password);

		if (data) {
			updateUser(data.profile);
			updateContracts(data.profile.rules);
		}

		return !!data;
	}

	async function getUser() {
		const token = AuthService.getToken();
		if (!token) {
			navigate("/login");
			return;
		}

		const responseUser = AuthService.getUser();
		if (!responseUser) {
			navigate("/login");
			return;
		}
		updateUser(responseUser.profile);

		if (responseUser?.profile?.rules) {
			updateContracts(responseUser.profile.rules);
		}
	}

	async function getContractActive() {
		const token = AuthService.getContractActive();
		if (!token) {
			return;
		}
		const find = contracts?.find(f => f.token === token);
		if (!find) {
			return;
		}

		updateContractActive(find);
	}

	function updateUser(user: UserProps) {
		dispatch({ type: "SET_USER", user });
	}

	function updateContracts(contracts: ContractsProps[]) {
		dispatch({ type: "SET_CONTRACTS", contracts });
	}
	function updateContractStartChange(contractStartChange: boolean) {
		dispatch({ type: "SET_CONTRACT_START_CHANGE", contractStartChange });
	}

	function updateContractActive(contractActive: ContractsProps) {
		AuthService.setContractActive(contractActive.token);
		AuthService.setContractPathActive(contractActive.path);
		dispatch({ type: "SET_CONTRACT_ACTIVE", contractActive });
	}

	return {
		user,
		contracts,
		contractActive,
		contractStartChange,
		submitUser,
		getUser,
		getContractActive,
		updateUser,
		updateContractActive,
		updateContractStartChange
	};
};

export default useAuth;
