import styled from "styled-components";

export const GroupBox = styled.div`
	position: relative;
	width: 100%;
	color: #3c3c3c;

	.card {
		margin: 1em 0;
		padding: 15px;
		.title-card {
			font-weight: 700;
			font-size: 20px;
			margin-bottom: -5px;
		}
	}

	.MuiInputBase-root {
		background-color: white;
	}
`;

export const GroupItem = styled.div`
	border-radius: 10px;
	padding: 20px;
	box-shadow: none;
	background-color: #f7f7f7;
	margin: 1em 0;

	.title-field {
		font-weight: 700;
		font-size: 16px;
	}
`;

type ColorBoxProps = {
	color: string;
};

export const ColorBox = styled.div<ColorBoxProps>`
	height: 40px;
	width: 100px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	padding: 6px;
	cursor: pointer;
	background-color: white;

	div {
		content: " ";
		border-radius: 20px;
		display: block;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background-color: ${props => props.color ?? "#FFFFFF"};
		border: 1px solid rgba(0, 0, 0, 0.1);
		font-weight: 300;
		font-size: 10px;
	}
`;

export const ImageBox = styled.div`
	height: 40px;
	width: 200px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	cursor: pointer;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		font-size: 15px;
		margin-right: 5px;
	}

	&:hover {
		background-color: #333;
		color: white;
	}
`;
