import { useState } from "react";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { FormFieldImageReturnProps } from "../types";

type useLogicProps = {
	setValue: UseFormSetValue<FieldValues>;
};

const useLogic = ({ setValue }: useLogicProps) => {
	const [openImage, setOpenImage] = useState<FormFieldImageReturnProps | null>(null);

	function onSelected(image?: string) {
		if (!!openImage && !!image) {
			setValue(openImage.name, image);
		}
		setOpenImage(null);
	}

	return { openImage, setOpenImage, onSelected };
};

export default useLogic;
