import api from "services/config/api";
import AuthService from "./auth";

export default class TemplateService {
	static async getTemplates(page: string, size: number) {
		try {
			const { data } = await api.get(`/templates/contract/${AuthService.getContractActive()}?page=${page ?? 1}&size=${size ?? 10}`);
			return data;
		} catch (e) {
			return null;
		}
	}
}
