export async function resizeImage(file: File, maxDimension: number = 250): Promise<string> {
	return new Promise<string>(resolve => {
		const reader = new FileReader();
		reader.onload = (e: ProgressEvent<FileReader>) => {
			const img = document.createElement("img");
			img.onload = () => {
				const canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");
				ctx!.drawImage(img, 0, 0);

				let width = img.width;
				let height = img.height;
				if (width > height) {
					if (width > maxDimension) {
						height *= maxDimension / width;
						width = maxDimension;
					}
				} else {
					if (height > maxDimension) {
						width *= maxDimension / height;
						height = maxDimension;
					}
				}
				canvas.width = width;
				canvas.height = height;

				ctx = canvas.getContext("2d");
				ctx!.drawImage(img, 0, 0, width, height);
				const extension = file.name.split(".").pop();
				const type = `image/${extension}`;
				const data_url = canvas.toDataURL(type);
				resolve(data_url);
			};
			img.src = e.target!.result as string;
		};
		reader.readAsDataURL(file);
	});
}
export const imageToBase64 = (file: File): Promise<string> =>
	new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = error => reject(error);
	});

export function renderFileSize(size: number) {
	if (Math.round(size / 100) / 10 > 1000) {
		return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`;
	} else {
		return `${(Math.round(size / 100) / 10).toFixed(1)} kb`;
	}
}

export function urlResizeImage(url: string, width = 200, height = 250) {
	return `${process.env.REACT_APP_BASE_URL}/external/resize?url=${url}&w=${width}&h=${height}`;
}

type urlCropImageProps = {
	url: string;
	width: number;
	height: number;
	x: number;
	y: number;
	zoom: number;
	adjustedX: number;
	adjustedY: number;
};

export function urlCropImage(props: urlCropImageProps) {
	return `${process.env.REACT_APP_BASE_URL}/external/crop?url=${props.url}&w=${props.width}&h=${props.height}&x=${props.x}&y=${props.y}&z=${props.zoom}&adjustedX=${props.adjustedX}&adjustedY=${props.adjustedY}`;
}

export function resizeImageWithMaxSize(originalWidth: number, originalHeight: number, maxSize: number): { width: number; height: number } {
	let newWidth, newHeight;

	if (originalWidth > originalHeight) {
		// A imagem é mais larga do que alta
		newWidth = maxSize;
		newHeight = (originalHeight / originalWidth) * maxSize;
	} else {
		// A imagem é mais alta do que larga ou tem a mesma altura e largura
		newHeight = maxSize;
		newWidth = (originalWidth / originalHeight) * maxSize;
	}

	return { width: newWidth, height: newHeight };
}
