import ImageLoading from "components/ImageLoading";
import useLibrary from "hooks/useLibrary";
import { useState } from "react";
import { urlResizeImage } from "utils/image";
import Swal from "utils/swal";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { GalleryItemsBox } from "./style";

export type GalleryImageProps = {
	alt: string;
	src: string;
	resizeUrl: string;
};

type GalleryItemsProps = {
	onSelected?: (image: string) => void;
	images: GalleryImageProps[];
};

const GalleryItems = ({ images, onSelected }: GalleryItemsProps) => {
	const { removeFile } = useLibrary();
	const [photoIndex, setPhotoIndex] = useState<number | null>(null);

	function handleImage(index: number) {
		setPhotoIndex(index);
		if (onSelected) {
			const image = images[index];
			onSelected(image.src);
		}
	}

	function questionDelete(url: string) {
		Swal.fire({
			icon: "question",
			title: "Remover",
			html: "Deseja realmente remover?",
			confirmButtonText: "Sim, remover",
			customClass: {
				confirmButton: "bg-error"
			},
			showCancelButton: true,
			cancelButtonText: "Cancelar",
			showLoaderOnConfirm: true,
			preConfirm: async () => {
				return await removeFile(url);
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then(result => {
			if (result.isConfirmed) {
				Swal.fire({
					icon: "success",
					title: `Imagem removida`,
					showConfirmButton: false,
					timer: 2000,
					toast: true,
					position: "bottom-end"
				});
			}
		});
	}

	const imageWidth = 200;
	const imageHeight = 250;

	return (
		<>
			<GalleryItemsBox className='masonry'>
				{images.map((item, index) => {
					return (
						<div key={item.alt} className='brick'>
							<figure
								className={`br-5 ${onSelected ? "pointer" : ""}


						 ${index == photoIndex ? "selected" : ""}`}
							>
								{!onSelected && (
									<div className='remove' title='Remover Imagem' onClick={() => questionDelete(item.src)} aria-hidden>
										<i className='far fa-trash-alt'></i>
									</div>
								)}
								<div onClick={() => handleImage(index)} aria-hidden>
									<ImageLoading
										src={urlResizeImage(item.src, imageWidth, imageHeight)}
										alt={item.alt}
										height={imageHeight}
										width={imageWidth}
										heightLoading={imageHeight}
									/>
								</div>
							</figure>
						</div>
					);
				})}
			</GalleryItemsBox>
			{!onSelected && <Lightbox open={photoIndex !== null} slides={images} index={photoIndex ?? 0} close={() => setPhotoIndex(null)} />}
		</>
	);
};

export default GalleryItems;
