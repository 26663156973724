import styled from "styled-components";

export const SelectContractBox = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999 !important;
	background-color: white;
`;

export const ContractsBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 16px;

	.item {
		min-width: 300px;
	}
`;

export const ContractClose = styled.div`
	position: absolute;
	border: 1px solid #ccc;
	top: 15px;
	right: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	padding: 5px;
	border-radius: 8px;
	background-color: white;
	cursor: pointer;

	span {
		font-weight: bold;
		&:hover {
			opacity: 0.8;
		}
	}
`;
