import { match } from "assert";
import { useDispatch, useSelector } from "react-redux";
import LibraryService from "services/api/library";
import { ReducersProps } from "store/reducers/types";
import { FileProps, LibraryProps } from "types/library";

const useLibrary = () => {
	const dispatch = useDispatch();
	const { files, totalFiles, pageActual, pageTotal, libraryActive } = useSelector((state: ReducersProps) => state.library);

	function updateLibraryActive(lib?: LibraryProps) {
		dispatch({ type: "SET_LIBRARY_ACTIVE", libraryActive: lib });
	}

	function updateFiles(files: FileProps[]) {
		dispatch({ type: "SET_FILES", files });
	}

	function updatePaginate(totalFiles: number, pageActual: number) {
		const perPage = LibraryService.perPage;
		const pageTotal = Math.ceil(totalFiles / perPage);
		dispatch({ type: "SET_FILES_PAGINATE", totalFiles, pageActual, pageTotal });
	}

	function updatePageActual(pageActual: number) {
		dispatch({ type: "SET_FILES_PAGE_ACTUAL", pageActual });
	}

	function addFile(file: FileProps) {
		dispatch({ type: "SET_FILES", files: [...files!, file] });
	}

	async function removeFile(url: string): Promise<boolean> {
		const find = files?.find(f => f.s3Url === url);
		if (find) {
			await LibraryService.deleteFile(find.token);
			const newList = files?.filter(f => f.s3Url !== url) as FileProps[];
			updateFiles(newList);
			return true;
		}
		return false;
	}

	return {
		files,
		totalFiles,
		pageActual,
		pageTotal,
		libraryActive,
		updateLibraryActive,
		updatePageActual,
		updatePaginate,
		updateFiles,
		addFile,
		removeFile
	};
};

export default useLibrary;
