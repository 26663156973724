import { useCallback, useEffect, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { CropContainer } from "./style";
import { getQueryParameters } from "utils/url";

type CropProps = {
	image: string;
	width: number;
	height: number;
	onCropCompleted: (croped: Croped | null) => void;
};

export type Croped = {
	zoom: number;
	position: Area;
	adjustedX: number;
	adjustedY: number;
};

const Crop = ({ image, width, height, onCropCompleted }: CropProps) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

	const onCrop = useCallback((_: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const params = useCallback(() => {
		return getQueryParameters(image);
	}, [])();

	useEffect(() => {
		const params = getQueryParameters(image);
		if (params?.z) {
			const z = Number(params.z);
			setZoom(z);
		}
	}, []);

	useEffect(() => {
		getCrop();
		return () => onCropCompleted(null);
	}, [zoom, croppedAreaPixels]);

	async function getCrop() {
		try {
			//@ts-ignore
			// const blob = await getCroppedImg(image, croppedAreaPixels, 0);
			if (croppedAreaPixels) {
				const adjustedX = (croppedAreaPixels.x * width) / (width * zoom);
				const adjustedY = (croppedAreaPixels.y * height) / (height * zoom);
				onCropCompleted({
					position: croppedAreaPixels,
					zoom,
					adjustedX,
					adjustedY
				});
			}
		} catch (e) {
			console.error(e);
		}
	}

	const urlImage = params?.url ?? image;

	return (
		<CropContainer>
			<Cropper
				image={urlImage}
				objectFit={width > height ? "horizontal-cover" : "vertical-cover"}
				cropSize={{ width, height }}
				initialCroppedAreaPixels={
					params?.w
						? {
								width: Number(params?.w),
								height: Number(params?.h),
								x: Number(params?.x),
								y: Number(params?.y)
						  }
						: undefined
				}
				crop={crop}
				minZoom={0.8}
				zoom={zoom}
				onCropChange={setCrop}
				onZoomChange={setZoom}
				onCropComplete={onCrop}
			/>
		</CropContainer>
	);
};

export default Crop;
