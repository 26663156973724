import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useController } from "react-hook-form";
import { FormFieldProps } from "./types";

const FormHidden = ({ name, control, rules }: FormFieldProps) => {
	const { field } = useController({
		name: `${name}`,
		control,
		rules,
		defaultValue: false
	});

	const title = !field.value ? "Visível" : "Não Visível";

	return (
		<IconButton color='primary' component='label' onClick={() => field.onChange(!field.value)} title={title}>
			{!field.value ? <Visibility fontSize='medium' color='action' /> : <VisibilityOff fontSize='medium' color='disabled' />}
		</IconButton>
	);
};

export default FormHidden;
