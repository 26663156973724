import { Alert, FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import ConfigService from "services/config/config";
import { FormFieldProps } from "./types";

const FormInput = ({
	name,
	placeholder,
	type,
	numOfRows,
	control,
	rules,
	changeLottie,
	label,
	...props
}: FormFieldProps) => {
	const maxLength = props?.maxLength ?? ConfigService.maxLength;

	return (
		<FormControl fullWidth>
			<Controller
				name={`${name}`}
				control={control}
				rules={{ ...rules, maxLength }}
				defaultValue=''
				render={({ field, fieldState }) => {
					const errorType = fieldState.error?.type;

					return (
						<>
							<TextField
								type={type}
								autoComplete='off'
								size='medium'
								rows={numOfRows}
								multiline={!!numOfRows}
								inputRef={field.ref}
								inputProps={{
									maxLength
								}}
								label={label}
								{...field}
								onChange={({ target }) => {
									const value = target.value.replaceAll("\n", "\r");
									changeLottie?.({ path: name, value });
									field.onChange(value);
								}}
								placeholder={placeholder}
								error={Boolean(fieldState.error)}
								aria-describedby='validation-basic-first-name'
								fullWidth
							/>
							{!!fieldState.error && errorMessage(errorType as string)}
						</>
					);
				}}
			/>
		</FormControl>
	);

	function errorMessage(errorType: string) {
		const message: { [key: string]: string } = {
			valueAsNumber: "O valor deste campo precisa ser um numeral válido.",
			valueAsDate: "O valor deste campo precisa ser uma data válida.",
			required: "Este campo é obrigatório.",
			maxLength: "Este campo ultrapassou o máximo caracteres.",
			minLength: "Este campo não atingiu o mínimo de caracteres"
		};

		const defaultMessage = "Este campo contém erro e precisa ser revisado";

		return (
			<Alert severity='error'>{message[errorType] ?? defaultMessage}</Alert>
		);
	}
};

export default FormInput;
