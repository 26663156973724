import { VideoActionProps, VideoReducerProps } from "./types";

const INITIAL_STATE: VideoReducerProps = {
	videos: [],
	total: 0
};

export default function reducer(state = INITIAL_STATE, action: VideoActionProps): VideoReducerProps {
	switch (action.type) {
		case "SET_VIDEOS":
			return { ...state, videos: action.videos };
		case "SET_TOTAL":
			return { ...state, total: action.total };
		default:
			return state;
	}
}
