import { RGBColor } from "react-color";

export function convertRGBToFC(rgb: RGBColor) {
	const divider = 255;
	return [rgb.r / divider, rgb.g / divider, rgb.b / divider, 1];
}

export function convertFCToHex(fc: number[]) {
	const mult = 255;
	return `#${valueToHex(Math.ceil(fc[0] * mult)) + valueToHex(Math.ceil(fc[1] * mult)) + valueToHex(Math.ceil(fc[2] * mult))}`;
}

function valueToHex(color: number) {
	var hex = color.toString(16).padStart(2, "0");
	return hex;
}
