import useAuth from "hooks/useAuth";
import useLibrary from "hooks/useLibrary";
import { useEffect, useState } from "react";
import LibraryService from "services/api/library";
import { GalleryImageProps } from "./items";

const useLogic = () => {
	const {
		files,
		totalFiles,
		pageActual,
		pageTotal,
		libraryActive,
		updateLibraryActive,
		updatePageActual,
		updatePaginate,
		updateFiles
	} = useLibrary();
	const { contractActive } = useAuth();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!libraryActive?.token) {
			getLibrary();
		}
	}, [contractActive]);

	useEffect(() => {
		if (libraryActive?.token && !files?.length) {
			getFiles(1);
		}
	}, [libraryActive]);

	useEffect(() => {
		if (pageActual === 1) {
			getMoreFiles(true);
		}
	}, [pageActual]);

	function getMoreFiles(force = false) {
		if (pageActual < pageTotal && (!isLoading || force)) {
			getFiles(pageActual + 1);
		}
	}

	async function getLibrary() {
		if (!!libraryActive?.token) {
			return;
		}
		const response = await LibraryService.getLibrary();
		updateLibraryActive(response);
	}

	async function getFiles(page = 1) {
		setIsLoading(true);

		try {
			const response = await LibraryService.getFiles(
				`${libraryActive?.token}`,
				page
			);
			const responseFiles = response.files ?? [];
			updateFiles(responseFiles);

			if (page == 1) {
				updatePaginate(response.total, 1);
			} else {
				updatePageActual(page);
			}
		} catch (error) {
			console.error("error on fetching images", error);
		} finally {
			setIsLoading(false);
		}
	}

	const imagens: GalleryImageProps[] =
		files?.map(m => ({
			src: `${m?.s3Url}`,
			resizeUrl: `${m?.resizeUrl}`,
			alt: m.filename
		})) ?? [];

	return { pageActual, isLoading, imagens, totalFiles, getMoreFiles };
};
export default useLogic;
