import { Alert, Card, Grid } from "@mui/material";
import DialogGallery from "components/Gallery/dialog";
import {
	FieldValues,
	UseFormSetValue,
	UseFormWatch,
	useFormContext
} from "react-hook-form";
import { TemplateInput } from "..";
import { ChangeLottieFn, FormGroupPros, HookFormProps } from "../types";
import { GroupBox, GroupItem } from "./style";
import useLogic from "./useLogic";
import { getNameHD } from "./util";
import FormItemVisible from "./visible";

type FormGroupType = {
	groups: FormGroupPros[];
	watch: UseFormWatch<FieldValues>;
	setValue: UseFormSetValue<FieldValues>;
	changeLottie: ChangeLottieFn;
} & HookFormProps;

const FormGroup = ({
	groups,
	control,
	watch,
	setValue,
	changeLottie
}: FormGroupType) => {
	const { openImage, setOpenImage, onSelected } = useLogic({ setValue });
	const {
		formState: { errors }
	} = useFormContext();

	const hasError = Object.keys(errors).length > 0;

	return (
		<GroupBox>
			{hasError && (
				<Alert severity='error'>
					Existem erros de validação, por favor revise antes de salvar.
				</Alert>
			)}
			{!!openImage && (
				<DialogGallery
					open={!!openImage}
					props={openImage}
					onClose={onSelected}
				/>
			)}

			{groups.map(group => (
				<Card key={group.title} className='card'>
					<div className='title-card'>{group.title}</div>
					{group.fields?.map(field => {
						const nameItem = getNameHD(field);
						const hiddenItem = watch(nameItem);

						return (
							<GroupItem key={field.fieldName}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Grid container paddingBottom={1}>
											<Grid item xs={11}>
												<div className='title-field'>{field.fieldName}</div>
											</Grid>
											<Grid item xs={1}>
												<FormItemVisible
													{...{ ...field, name: nameItem, control }}
												/>
											</Grid>
										</Grid>

										<Grid
											item
											xs={12}
											sx={{ display: !hiddenItem ? "" : "none" }}
										>
											<TemplateInput
												{...{ ...field, control, setOpenImage, changeLottie }}
											/>
										</Grid>
									</Grid>
								</Grid>
							</GroupItem>
						);
					})}
				</Card>
			))}
		</GroupBox>
	);
};

export default FormGroup;
