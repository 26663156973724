import useAuth from "hooks/useAuth";
import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import TabToTop from "../TabToTop/TabToTop";
import LayoutContract from "./Contract";

const App = () => {
	const { user, contracts, getUser, getContractActive } = useAuth();

	React.useEffect(() => {
		document.title = "Autonomidia";
		getUser();
		startStore();
	}, []);

	React.useEffect(() => {
		if (!!contracts?.length) {
			getContractActive();
		}
	}, [contracts]);

	if (!user?.username) {
		return <div />;
	}

	function startStore() {
		//The created store
		document.querySelector("body")?.classList.add("ltr", "main-body", "app", "sidebar-mini");
		document.querySelector("body")?.classList.remove("error-page1", "bg-primary");
	}

	return (
		<Fragment>
			<LayoutContract />
			<div className='horizontalMenucontainer'>
				<TabToTop />
				<div className='page'>
					<div className='open'>
						<Header />
						<Sidebar />
					</div>
					<div className='main-content app-content'>
						<div className='side-app'>
							<div className='main-container container-fluid'>
								<Outlet />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default App;
