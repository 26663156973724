import { Button, DialogActions, DialogContent } from "@mui/material";
import Crop, { Croped } from "components/Crop";
import DialogComponent from "components/Dialog";
import { FormFieldImageReturnProps } from "components/Form/types";
import { useCallback, useEffect, useState } from "react";
import { urlCropImage } from "utils/image";
import { getQueryParameters } from "utils/url";
import Gallery from ".";

type DialogGalleryProps = {
	open: boolean;
	props: FormFieldImageReturnProps;
	onClose: (image?: string) => void;
};

const DialogGallery = (props: DialogGalleryProps) => {
	const [selected, setSelected] = useState<string | undefined>();
	const [url, setUrl] = useState<string | null>(null);
	const [croped, setCroped] = useState<string | undefined>();
	const params = getQueryParameters(url ?? "");

	useEffect(() => {
		if (props.props.value.includes("crop?")) {
			setUrl(props.props.value);
		}
	}, [props.props.value]);

	const inCrop = !!url;

	function onCropCompleted(croped: Croped | null) {
		if (croped) {
			const newUrl = urlCropImage({
				url: params?.url ?? url!,
				width: croped.position.width,
				height: croped.position.height,
				x: croped.position.x,
				y: croped.position.y,
				zoom: croped.zoom,
				adjustedX: croped.adjustedX,
				adjustedY: croped.adjustedY
			});
			setCroped(newUrl);
		}
	}

	const buttonActiveCallback = useCallback(() => {
		if (!inCrop) {
			return !!selected;
		} else {
			return !!croped;
		}
	}, [selected, croped]);

	const buttonActive = buttonActiveCallback();

	const width = props.props.width ?? 800;
	const height = props.props.height ?? 600;

	return (
		<DialogComponent
			title={inCrop ? `Recorte a Imagem` : "Escolher Imagem"}
			open={props.open}
			onClose={props.onClose}
		>
			<DialogContent
				dividers
				sx={{ padding: 2, minWidth: "100%", maxWidth: "100%" }}
			>
				{inCrop ? (
					<div
						style={{
							minHeight: "80vh",
							position: "relative",
							padding: 20,
							overflow: "hidden"
						}}
					>
						<Crop
							image={url!.toString()}
							width={width}
							height={height}
							onCropCompleted={onCropCompleted}
						/>
					</div>
				) : (
					<Gallery onSelected={image => setSelected(image)} />
				)}
			</DialogContent>
			<DialogActions>
				{inCrop ? (
					<>
						<Button variant='outlined' onClick={chooseAnotherImage}>
							Escolher outra imagem
						</Button>
						<Button
							variant='contained'
							onClick={onCropClick}
							sx={{ background: "black" }}
							disabled={!croped}
						>
							Cortar
						</Button>
					</>
				) : (
					<Button
						variant='contained'
						onClick={onSelectClick}
						sx={{ background: "black" }}
						disabled={!buttonActive}
					>
						Selecionar
					</Button>
				)}
			</DialogActions>
		</DialogComponent>
	);

	function onSelectClick() {
		if (!croped) {
			setUrl(selected!);
			return;
		}
		props.onClose(croped);
	}

	function onCropClick() {
		props.onClose(croped);
		setUrl(null);
	}

	function chooseAnotherImage() {
		setUrl(null);
		setCroped(undefined);
	}
};

export default DialogGallery;
