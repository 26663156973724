import qs from "qs";
import oauth from "../config/oauth";

class AuthService {
	static tokenKey = "autonomidia_token";
	static contractKey = "autonomidia_contract_key";
	static contractPath = "autonomidia_contract_path";
	static user = "user";

	static getToken() {
		return localStorage.getItem(this.tokenKey) ?? "";
	}

	static getContractActive() {
		return localStorage.getItem(this.contractKey) ?? "";
	}
	static getContractPathActive() {
		return localStorage.getItem(this.contractPath);
	}

	static setContractActive(hash: string) {
		localStorage.setItem(this.contractKey, hash);
	}
	static setContractPathActive(path: string) {
		localStorage.setItem(this.contractPath, path);
	}

	static async submit(username: string, password: string) {
		try {
			const userData = qs.stringify({ username, password });
			const { data } = await oauth.post("/login", userData);

			localStorage.setItem(this.user, JSON.stringify(data));
			localStorage.setItem(this.tokenKey, data?.token.access_token);
			return data;
		} catch (e) {
			console.log("error on user submission", e);
		}
	}

	static getUser() {
		const user = localStorage.getItem(this.user);
		if (user) return JSON.parse(user);
	}

	static logOut() {
		localStorage.removeItem(this.tokenKey);
		localStorage.removeItem(this.contractKey);
		localStorage.removeItem(this.contractPath);
		localStorage.removeItem(this.user);
	}
}

export default AuthService;
