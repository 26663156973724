export function getQueryParameters(url?: string): Record<string, string> {
	if (!url?.includes("crop?")) {
		return {};
	}
	const queryString = url.split("?")[1]; // Obtém a parte da query da URL
	if (!queryString) {
		return {}; // Retorna um objeto vazio se não houver query
	}

	const params = new URLSearchParams(queryString);
	const queryParams: Record<string, string> = {};

	for (const [key, value] of params.entries()) {
		queryParams[key] = value;
	}

	return queryParams;
}
