import { TemplateActtionProps, TemplateReducerProps } from "./types";

const INITIAL_STATE: TemplateReducerProps = {
	templates: [],
	total: 0,
};

export default function reducer(state = INITIAL_STATE, action: TemplateActtionProps): TemplateReducerProps {
	switch (action.type) {
		case "SET_TEMPLATES":
			return { ...state, templates: action.templates };
		case "SET_TOTAL":
			return { ...state, total: action.total };
		default:
			return state;
	}
}
