import { FormFieldProps } from "./../types";

export function getNameHD(props: FormFieldProps) {
	if (props.type == "text" && props.path) {
		const sp = props.path.toString().split(".t");
		return `${sp[0]}.hd`;
	}

	return `${props.path}.hd`;
}
