import  _throttle  from "lodash/throttle";
import React, { useRef, useEffect } from "react";

type ScrollDetectorProps = {
	children: React.ReactNode;
	onReachEnd: () => void;
};

const ScrollDetector: React.FC<ScrollDetectorProps> = ({ children, onReachEnd }) => {
	const sentinelRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleScrollThrottled = _throttle(handleScroll, 200);
		window.addEventListener("scroll", handleScrollThrottled);
		return () => {
			window.removeEventListener("scroll", handleScrollThrottled);
		};
	}, [onReachEnd]);

	const handleScroll = () => {
		const sentinel = sentinelRef.current;
		if (sentinel) {
			const { top } = sentinel.getBoundingClientRect();
			const { clientHeight } = document.documentElement;
			if (top <= clientHeight) {
				onReachEnd();
			}
		}
	};

	return (
		<div>
			{children}
			<div ref={sentinelRef} style={{ height: "1px" }} />
		</div>
	);
};

export default ScrollDetector;
