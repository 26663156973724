import { Verified } from "@mui/icons-material";
import useAuth from "hooks/useAuth";
import { HeaderContractActiveBox } from "./style";
import SelectContract from "components/SelectContract";

const HeaderContractActive = () => {
	const { contractActive, contractStartChange, updateContractStartChange } = useAuth();

	if (!contractActive) {
		return <div />;
	}

	function handleClick() {
		updateContractStartChange(true);
	}

	return (
		<HeaderContractActiveBox>
			<Verified sx={{ fontSize: "0.9rem", color: "var(--primary-bg-color)" }} />
			<a title='Trocar de Contrato' onClick={handleClick}>
				{contractActive?.name}
			</a>
		</HeaderContractActiveBox>
	);
};

export default HeaderContractActive;
