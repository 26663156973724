import { AssignmentTurnedIn, Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import ButtonComponent from "components/Button";
import { FormInput } from "components/Form";
import useAuth from "hooks/useAuth";
import useLibrary from "hooks/useLibrary";
import useTemplate from "hooks/useTemplate";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import { ContractsProps } from "store/reducers/user/types";
import { ContractClose, ContractsBox, SelectContractBox } from "./style";

const SelectContract = () => {
	const { control, watch } = useForm();
	const { updateTemplates } = useTemplate();
	const { updateLibraryActive, updateFiles } = useLibrary();
	const {
		contracts,
		contractStartChange,
		updateContractActive,
		updateContractStartChange
	} = useAuth();

	function onSelected(item: ContractsProps) {
		updateContractStartChange(false);
		updateLibraryActive(undefined);
		updateTemplates([]);
		updateFiles([]);
		updateContractActive(item);
	}

	const search = watch("search");

	const listContracts = search
		? contracts?.filter(
				f =>
					f.name.toLowerCase().includes(search.toLowerCase()) ||
					f.name.toLowerCase().includes(search.toLowerCase())
		  )
		: contracts;

	return (
		<SelectContractBox>
			<Box sx={{ p: 1 }}>
				<Box sx={{ p: 1 }}>
					<Row>
						<Col xs={12}>
							<div className='breadcrumb-header justify-content-between'>
								<div className='left-content'>
									<span className='main-content-title mg-b-0 mg-b-lg-1'>
										<AssignmentTurnedIn />{" "}
										<strong>Selecione um Contrato</strong>
									</span>
								</div>
							</div>
						</Col>

						<Col md={3} xs={12}>
							<FormInput
								type='text'
								name='search'
								placeholder='Pesquisar contrato'
								control={control}
							/>
						</Col>
					</Row>
				</Box>
				<Box sx={{ p: 1 }}>
					<Row>
						<ContractsBox>
							{!listContracts?.length && (
								<Box sx={{ pt: 1 }}>
									<h4>Sem resultado</h4>
								</Box>
							)}
							{listContracts?.map((item, index) => (
								<Card className='item' key={`contract-${index}`}>
									<CardBody>
										<CardTitle>{item.name}</CardTitle>
										<CardText>Licença : {item.license_number}</CardText>
										<ButtonComponent
											type='button'
											classType='primary'
											onClick={() => onSelected(item)}
										>
											Selecionar
										</ButtonComponent>
									</CardBody>
								</Card>
							))}
						</ContractsBox>
					</Row>
				</Box>
				{contractStartChange && (
					<ContractClose onClick={() => updateContractStartChange(false)}>
						<Close style={{ fontSize: "0.8rem" }} />
						<span>Cancelar</span>
					</ContractClose>
				)}
			</Box>
		</SelectContractBox>
	);
};

export default SelectContract;
