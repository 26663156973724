import AuthService from "services/api/auth";
import axios from "axios";
import ConfigService from "./config";

const api = axios.create({
	baseURL: ConfigService.ApiUrl,
	timeout: 30000,
	headers: {
		"Content-Type": "application/json"
	}
});

api.interceptors.request.use(
	config => {
		const token = localStorage.getItem(AuthService.tokenKey);
		config.headers!.Authorization = `Bearer ${token}`;
		return config;
	},
	error => error
);

export default api;
