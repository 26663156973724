import styled from "styled-components";

export const CropContainer = styled.div`
	width: 100%;
	max-width: 100%;
	height: 80%;

	.reactEasyCrop_Container {
		background-color: black;
	}
`;
