import { Grid } from "@mui/material";
import FormSwitch from "../switch";
import { FormFieldProps } from "../types";
import FormHidden from "../visibility";

const FormItemVisible = (props: FormFieldProps) => {
	return (
		<Grid item textAlign='right'>
			<FormHidden {...props} />
		</Grid>
	);
};

export default FormItemVisible;
