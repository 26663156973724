import { PhotoAlbum } from "@mui/icons-material";
import { FormControl } from "@mui/material";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { resizeImageWithMaxSize } from "utils/image";
import { ImageBox } from "./group/style";
import { FormFieldProps } from "./types";

const FormImage = ({
	name,
	control,
	setOpenImage,
	path,
	changeLottie
}: FormFieldProps) => {
	return (
		<FormControl fullWidth>
			<Controller
				name={`${name}`}
				control={control}
				defaultValue=''
				render={({ field, formState }) => {
					useEffect(() => {
						changeLottie?.({
							path: name,
							value: field.value
						});
					}, [field.value]);

					return (
						<div>
							<ImageBox
								onClick={() => {
									const index = path!.replace(/[^\d]+/g, "");
									const obj = formState.defaultValues!.assets[Number(index)];
									setOpenImage?.({
										name: field.name,
										value: field.value,
										...resizeImageWithMaxSize(
											Number(obj?.w),
											Number(obj?.h),
											600
										)
									});
								}}
								role='button'
							>
								<PhotoAlbum />
								<span>Selecionar Imagem</span>
							</ImageBox>

							<div style={{ textAlign: "center", marginTop: 20 }}>
								<figure>
									<img src={field.value} alt='image' style={{ width: 100 }} />
								</figure>
							</div>
						</div>
					);
				}}
			/>
		</FormControl>
	);
};

export default FormImage;
