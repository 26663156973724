import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./shade/Loaders/Loaders";
import RoutesApp from "./routes";
import { Provider } from "react-redux";
import store from "store";

const App = () => {
	return (
		<Provider store={store}>
			<BrowserRouter basename='/'>
				<React.Suspense fallback={<Loader />}>
					<RoutesApp />
				</React.Suspense>
			</BrowserRouter>
			<ToastContainer />
		</Provider>
	);
};

export default App;
