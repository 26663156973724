import { PhotoLibrary, VideoLibrary, Slideshow, Info } from "@mui/icons-material";

export const MENUITEMS = [
	{
		menutitle: "Principal",
		Items: [
			{
				title: "Dashboard",
				icon: (
					<svg xmlns='http://www.w3.org/2000/svg' className='side-menu__icon' width='24' height='24' viewBox='0 0 24 24'>
						<path d='M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z' />
					</svg>
				),
				path: "/",
				type: "link",
				selected: false,
				bookmark: true,
				active: false
			}
		]
	},

	{
		menutitle: "Gestão",
		Items: [
			{
				title: "Vídeos",
				icon: <VideoLibrary className='side-menu__icon' />,
				type: "link",
				path: "/videos",
				selected: false,
				bookmark: true,
				active: false
			},
			{
				title: "Biblioteca",
				icon: <PhotoLibrary className='side-menu__icon' />,
				type: "link",
				path: "/library",
				selected: false,
				bookmark: true,
				active: false
			}
		]
	},

	{
		menutitle: "Modelos",
		Items: [
			{
				title: "Templates",
				icon: <Slideshow className='side-menu__icon' />,
				type: "link",
				path: "/templates",
				selected: false,
				active: false
			},
			{
				title: "Documentação",
				icon: <Info className='side-menu__icon' />,
				type: "link",
				path: "/help",
				selected: false,
				active: false
			}
		]
	}
];
