import { Grid } from "@mui/material";
import { getFieldPath } from "utils/lottieUtils";
import FormColor from "./color";
import FormGroup from "./group";
import FormImage from "./image";
import FormInput from "./input";
import FormSelect from "./select";
import FormSwitch from "./switch";
import { ChangeLottieFn, FormFieldProps } from "./types";

const TemplateInput = (
	props: FormFieldProps & { changeLottie: ChangeLottieFn }
) => {
	const name = `${props.path}${getFieldPath(props.type)}`;
	const commonProps = { ...props, name };

	switch (props.type) {
		case "color":
			return <FormColor {...commonProps} />;
		case "shape":
			return <FormColor {...commonProps} />;
		case "text":
			return (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormInput {...commonProps} />
					</Grid>
					<Grid item xs={12}>
						<FormColor
							{...{
								...props,
								name: `${props.path}${getFieldPath("text-color")}`,
								type: "text-color"
							}}
						/>
					</Grid>
				</Grid>
			);

		case "image":
			return <FormImage {...{ ...props, name, path: `${props.path}` }} />;

		default:
			return <div>{`${props.type} em desenvolvimento`}</div>;
	}
};

export {
	FormColor,
	FormGroup,
	FormImage,
	FormInput,
	FormSelect,
	FormSwitch,
	TemplateInput
};
