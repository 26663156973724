import SelectContract from "components/SelectContract";
import useAuth from "hooks/useAuth";

const LayoutContract = () => {
	const { contractActive, contractStartChange } = useAuth();

	if (!contractActive || contractStartChange) {
		return <SelectContract />;
	}
	return <div />;
};

export default LayoutContract;
