import { Col, Row } from "react-bootstrap";
import GalleryItems from "./items";
import { GalleryBox } from "./style";
import useLogic from "./useLogic";
import ScrollDetector from "components/ScrollDetector";
import Loader from "shade/Loaders/Loaders";

type GalleryProps = {
	onSelected?: (image: string) => void;
};

const Gallery = (props: GalleryProps) => {
	const { isLoading, imagens, totalFiles, getMoreFiles } = useLogic();

	return (
		<GalleryBox>
			{isLoading && <Loader />}

			<ScrollDetector onReachEnd={() => getMoreFiles()}>
				<GalleryItems images={imagens} onSelected={props.onSelected} />
			</ScrollDetector>

			<Row className=' mb-5'>
				<Col xs={12} className='mt-1 text-dark'>
					{imagens.length}/{totalFiles} imagens
				</Col>
			</Row>
		</GalleryBox>
	);
};

export default Gallery;
