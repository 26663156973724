import React from "react";
import { Button } from "react-bootstrap";

type ButtonComponentProps = {
	children: React.ReactNode;
	isLoading?: boolean;
	type?: "button" | "submit" | "reset";
	classType?: "primary" | "secondary" | "danger" | "warning" | "success";
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ButtonComponent = ({ children, isLoading, type, classType = "primary", onClick }: ButtonComponentProps) => {
	return (
		<div className='btn-animation'>
			<Button
				disabled={isLoading}
				variant=''
				type={type}
				className={`btn btn-${classType} btn-block ${isLoading && "btn-loaders"}`}
				onClick={onClick}
			>
				{children}
			</Button>
		</div>
	);
};

export default ButtonComponent;
