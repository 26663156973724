import React from "react";
import { Route } from "react-router-dom";

const NoAuth = React.lazy(() => import("./NoAuth"));
const Custompages = React.lazy(() => import("shade/layouts/custompages"));
const Login = React.lazy(() => import("pages/Login"));

const OpenRoutes = (
	<Route>
		<Route path={`/`} element={<NoAuth />}>
			<Route path={"/login"} element={<Login />} />
		</Route>

		<Route path={`/`} element={<Custompages />}>
			<Route path='*' element={<h1>404</h1>} />
		</Route>
	</Route>
);

export default OpenRoutes;
