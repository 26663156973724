import { FormFieldType } from "components/Form/types";

export function getFieldPath(type: FormFieldType) {
	switch (type) {
		case "shape":
			return ".c.k";
		case "text":
			return ".s.t";
		case "text-color":
			return ".s.fc";
		case "image":
			return ".p";
	}

	return "";
}
