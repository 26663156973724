import { Person } from "@mui/icons-material";
import { Avatar } from "@mui/material";

const AvatarUser = () => {
	return (
		<Avatar style={{ height: 35, width: 35 }}>
			<Person />
		</Avatar>
	);
};

export default AvatarUser;
