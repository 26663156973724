import { UserActtionProps, UserReducerProps } from "./types";

const INITIAL_STATE: UserReducerProps = {
	contractStartChange: false,
	contracts: []
};

export default function reducer(state = INITIAL_STATE, action: UserActtionProps): UserReducerProps {
	switch (action.type) {
		case "SET_USER":
			return { ...state, user: action.user };
		case "SET_CONTRACTS":
			return { ...state, contracts: action.contracts };
		case "SET_CONTRACT_ACTIVE":
			return { ...state, contractActive: action.contractActive };
		case "SET_CONTRACT_START_CHANGE":
			return { ...state, contractStartChange: action.contractStartChange };
		default:
			return state;
	}
}
