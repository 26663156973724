import axios from "axios";
import AuthService from "../api/auth";
import ConfigService from "./config";

const oauth = axios.create({
	baseURL: ConfigService.ApiUrl,
	timeout: 30000,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded"
	}
});

oauth.interceptors.request.use(
	config => {
		const token = AuthService.getToken();
		config.headers!.Authorization = `Bearer ${token}`;
		return config;
	},
	error => error
);

export default oauth;
