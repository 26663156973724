import styled from "styled-components";

export const GalleryBox = styled.div`
	width: 100%;
`;

export const GalleryItemsBox = styled.div`
	width: 100%;
	position: relative;
	display: inline-flex;
	display: inline-flex;
	flex-wrap: wrap;
	gap: 1rem;

	@media (max-width: 600px) {
		.brick {
			width: 45%;
		}
	}

	.brick figure {
		position: relative;
	}

	.pointer {
		cursor: pointer;
	}
	.selected {
		border: 2px solid black;
	}

	.remove {
		z-index: 2;
		cursor: pointer;
		position: absolute;
		top: 4px;
		right: 4px;
		color: red;
		font-size: 16px;
		&:hover {
			opacity: 0.7;
		}
	}
`;
