import api from "services/config/api";
import { LibraryProps } from "types/library";
import AuthService from "./auth";

export default class LibraryService {
	static minioUrl = process.env.REACT_APP_MINIO_URL as string;
	static perPage = 10;

	static async getLibraries() {
		try {
			const { data } = await api.get(
				`/libraries/${AuthService.getContractActive()}`
			);
			return data;
		} catch (e) {
			return null;
		}
	}

	static async getLibrary() {
		try {
			const { data } = await api.get(
				`/library/${AuthService.getContractActive()}`
			);
			return data;
		} catch (e) {
			return null;
		}
	}

	static async createLibrary(payload: LibraryProps) {
		try {
			const { data } = await api.post(`/library`, payload);
			return data;
		} catch (e) {
			return null;
		}
	}

	static async updateLibrary(uuid: string, payload: LibraryProps) {
		try {
			const { data } = await api.put(`/library/${uuid}`, payload);
			return data;
		} catch (e) {
			return null;
		}
	}
	static async deleteLibrary(uuid: string) {
		try {
			const { data } = await api.delete(`/library/${uuid}`);
			return data;
		} catch (e) {
			return null;
		}
	}

	static async getFiles(uuid: string, page = 1) {
		try {
			const { data } = await api.get(
				`/library/files/${uuid}?page=${page}&size=${this.perPage}`
			);
			return data;
		} catch (e) {
			return null;
		}
	}

	static async getFile(uuid: string) {
		try {
			const { data } = await api.get(`/library/file/${uuid}`);
			return data;
		} catch (e) {
			return null;
		}
	}
	static async createFile(payload: FormData) {
		try {
			const { data } = await api.post(`/library/file`, payload);
			return data;
		} catch (e) {
			return null;
		}
	}
	static async deleteFile(uuid: string) {
		try {
			const { data } = await api.delete(`/library/file/${uuid}`);
			return data;
		} catch (e) {
			return null;
		}
	}
	static urlFile(file: string) {
		return (
			this.minioUrl +
			AuthService.getContractPathActive() +
			"/autonomidia/" +
			file
		);
	}

	static urlVideo(video: string) {
		return `${
			this.minioUrl
		}/${AuthService.getContractPathActive()}/video/${video}.mp4`;
	}
}
