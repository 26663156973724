import { FormControl } from "@mui/material";
import Tooltip from "rc-tooltip";
import { SketchPicker } from "react-color";
import { Controller } from "react-hook-form";
import { convertFCToHex, convertRGBToFC } from "utils/color";
import { ColorBox } from "./group/style";
import { FormFieldProps } from "./types";

const FormColor = ({
	name,
	path,
	control,
	rules,
	changeLottie
}: FormFieldProps) => {
	return (
		<FormControl fullWidth>
			<Controller
				name={`${name}`}
				control={control}
				rules={rules}
				defaultValue=''
				render={({ field }) => (
					<div
						style={{
							position: "relative"
						}}
					>
						<Tooltip
							placement='topLeft'
							trigger={["click"]}
							overlay={
								<SketchPicker
									color={field.value}
									onChange={color => {
										if (path) {
											const value = convertRGBToFC(color.rgb);
											field.onChange(value);
											changeLottie?.({ path: name, value });
										} else {
											field.onChange(color.hex);
										}
									}}
								/>
							}
							destroyTooltipOnHide
						>
							<ColorBox
								color={path ? convertFCToHex(field.value) : field.value}
							>
								<div>Cor</div>
							</ColorBox>
						</Tooltip>
					</div>
				)}
			/>
		</FormControl>
	);
};

export default FormColor;
