import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import user from "store/reducers/user";
import template from "store/reducers/template";
import video from "store/reducers/video";
import library from "store/reducers/library";

const reducer = combineReducers({
	user,
	template,
	video,
	library
});

const store = configureStore({
	reducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false
		})
});
export default store;
