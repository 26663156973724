import { LibraryReducerProps, LibraryActtionProps } from "./types";

const INITIAL_STATE: LibraryReducerProps = {
	files: [],
	totalFiles: 0,
	pageActual: 0,
	pageTotal: 0
};

export default function reducer(state = INITIAL_STATE, action: LibraryActtionProps): LibraryReducerProps {
	switch (action.type) {
		case "SET_LIBRARY_ACTIVE":
			return { ...state, libraryActive: action.libraryActive };
		case "SET_FILES":
			return { ...state, files: action.files };
		case "SET_FILES_PAGINATE":
			return { ...state, totalFiles: action.totalFiles, pageActual: action.pageActual, pageTotal: action.pageTotal };
		case "SET_FILES_PAGE_ACTUAL":
			return { ...state, pageActual: action.pageActual };
		default:
			return state;
	}
}
