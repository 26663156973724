import styled from "styled-components";

export const HeaderContractActiveBox = styled.div`
	display: flex;
	flex-direction: row;
	gap: 3px;
	margin-left: 20px;
	align-items: center;

	a {
		cursor: pointer;
		font-weight: bold;
	}
`;
